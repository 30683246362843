import { Action, createAction, createReducer, on, props } from '@ngrx/store';
import { ITransaction } from '../interfaces';

export const transactionDefault = (): ITransaction => ({
  slipType: 0,
  pickupItems: [],
  tmpAddition: {},
  addition: [],
});

export const actionTransaction = createAction('[STORE Transaction]', props<ITransaction>());

const ReducerFunction = createReducer(
  transactionDefault(),
  on(actionTransaction, (state, reload) => {
    return reload;
  }),
);

export function reducer(state: ITransaction, action: Action) {
  return ReducerFunction(state, action);
}
